<template>
  <!-- Popup -->
  <Popup ref="popup" />

  <div class="content-body">
    <div class="row sticky-top">
      <b>NFT Lookup</b>
      <div class="col-lg-6 col-sm-12 col-xs-12">
        <ul class="settings-menu">
          <button
            class="btn btn-primary"
            type="button"
            id="collection-sorting"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sorting
          </button>
          <ul class="dropdown-menu" aria-labelledby="collection-sorting">
            <li v-for="_sortOpt in sortOptions" :key="_sortOpt">
              <a
                class="dropdown-item"
                href="#"
                @click="(sorting = _sortOpt), sortNfts()"
                :class="sorting === _sortOpt ? 'current' : ''"
                >{{ _sortOpt }}</a
              >
            </li>
          </ul>

          &nbsp;

          <button
            class="btn btn-primary"
            type="button"
            id="collection-selector"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ collection }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="collection-selector">
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="selectCollection('PixelGuyClub')"
                :class="collection === 'PixelGuyClub' ? 'current' : ''"
                >PixelGuyClub</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="selectCollection('Shorai')"
                :class="collection === 'Shorai' ? 'current' : ''"
                >Shorai</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="selectCollection('Kensho')"
                :class="collection === 'Kensho' ? 'current' : ''"
                >Kensho</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="selectCollection('Kensho Exclusive')"
                :class="collection === 'Kensho Exclusive' ? 'current' : ''"
                >Kensho Exclusive</a
              >
            </li>
          </ul>

          &nbsp; &nbsp;
          <div style="border-left: 2px solid #0075ff; height: 60px"></div>
          &nbsp; &nbsp;
          <div>
            <label for="nft2LookupFrom" style="color: white">From</label>
            <input
              type="number"
              id="nft2LookupFrom"
              min="1"
              step="1"
              :max="maxNftsInCollection"
              v-model="nft2LookupFrom"
              @change="correctNft2LookupFromTo()"
              style="width: 5em"
            />
          </div>
          &nbsp;
          <div>
            <label for="nft2LookupTo" style="color: white">to</label>
            <input
              type="number"
              id="nft2LookupTo"
              :min="nft2LookupFrom"
              step="1"
              :max="maxNftsInCollection"
              v-model="nft2LookupTo"
              @change="correctNft2LookupFromTo()"
              style="width: 5em"
            />
          </div>
          &nbsp; &nbsp;

          <button
            class="btn btn-primary"
            type="button"
            @click="addNfts(nft2LookupFrom, nft2LookupTo)"
          >
            Add NFTs
          </button>
        </ul>
      </div>
    </div>

    <div class="container">
      <div class="col-12" v-if="filteredNfts.length > 0">
        <div class="card filter-tab">
          <div class="card-body bs-0 p-0 bg-transparent">
            <div class="row">
              <div
                class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                v-for="_tokenId in filteredNfts"
                :key="_tokenId"
              >
                <div class="card items">
                  <div class="card-body">
                    <div class="items-img position-relative">
                      <img
                        :src="nftInfo[_tokenId].image"
                        class="img-fluid rounded mb-3"
                        :alt="_tokenId"
                      />
                    </div>

                    <label>
                      <h4 class="card-title">#{{ _tokenId }}</h4>
                    </label>

                    <NftCardLeveling
                      :nft="nftInfo[_tokenId]"
                      v-model:nftLevelAfterUpgrade="
                        nftInfo[_tokenId].nftLevelAfterUpgrade
                      "
                      v-model:expTokensForUpgrade="
                        nftInfo[_tokenId].expTokensForUpgrade
                      "
                      :noAllowanceForLeveling="true"
                      :stakersExpBalance="999999999999999"
                      :maxLevel="maxLevel"
                      @calculateExpTokensForUpgradeAndSetPctTo0="
                        getExpForFullLvlUpgrade(_tokenId)
                      "
                      @checkExpTokensForUpgrade="
                        checkExpTokensForUpgrade(_tokenId)
                      "
                      @upgrade="
                        upgradeSpecificNft(
                          _tokenId,
                          Math.ceil(nftInfo[_tokenId].expTokensForUpgrade)
                        )
                      "
                    />

                    <br />
                    <h4 style="margin-top: 20px">Graveyard Info</h4>
                    <span>
                      This NFT can be sacrificed for
                      <b>{{ nftInfo[_tokenId].sacrificePrice }} EXP</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import NftLeveler from "../../artifacts/contracts/NftLeveler.sol/NftLeveler.json";
import Graveyard from "../../artifacts/contracts/Graveyard.sol/Graveyard.json";

import Popup from "./Popup.vue";
import NftCardLeveling from "./nft/NftCardLeveling.vue";

// TODO SmartBch Mainnet BEGIN //
import minpowerPgc from "../bchMainnet/pixelguy-minpower.json";
import minpowerShorai from "../bchMainnet/shorai-minpower.json";
import minpowerKensho from "../bchMainnet/kensho-minpower.json";

let pixelguy = {
  pictureLink:
    "https://raw.githubusercontent.com/NftClubCash/PixelGuyClub/main/png/",
  discountExpTokensX1k: 850,
  expForLvl0: 850,
  minpower: minpowerPgc,
};

let shorai = {
  pictureLink: "https://nftraritycash.github.io/nftassets/shorai-optimized/",
  discountExpTokensX1k: 920,
  expForLvl0: 1380,
  minpower: minpowerShorai,
};

let kensho = {
  pictureLink: "https://raw.githubusercontent.com/NftClubCash/kensho/main/png/",
  discountExpTokensX1k: 1000,
  expForLvl0: 1700,
  minpower: minpowerKensho,
};

let kenshoX = {
  pictureLink:
    "https://raw.githubusercontent.com/NftClubCash/kensho-exclusive/main/png/",
  discountExpTokensX1k: 1000,
  expForLvl0: 1700,
  minpower: minpowerKensho,
};

const nftCollection = {
  "0xf928B82061bc531e3B24B8CF3736724e35988b7a": shorai,
  "0xC995199DC53922caCE4f6ac14A476eF8c9429387": pixelguy,
  "0xFD325598bbB5672f8136A5b35885722a3d440aE0": kensho,
  "0x47C388a14712434B14b2a111ce4042B537D69d4a": kenshoX,
};
// SmartBch MAINNET END //

// // TODO SmartBch Testnet BEGIN //
// import minpower from "../bchTestnet/minpower.json";

// let pixelguyTest = {
//   pictureLink:
//     "https://raw.githubusercontent.com/NftClubCash/PixelGuyClub/main/png/",
//   discountExpTokensX1k: 850,
//   expForLvl0: 850,
// };

// let shoraiTest = {
//   pictureLink: "https://nftraritycash.github.io/nftassets/shorai-optimized/",
//   discountExpTokensX1k: 920,
//   expForLvl0: 1380,
// };

// let kenshoTest = {
//   pictureLink: "https://raw.githubusercontent.com/NftClubCash/kensho/main/png/",
//   discountExpTokensX1k: 1000,
//   expForLvl0: 1700,
// };

// let kenshoXTest = {
//   pictureLink:
//     "https://raw.githubusercontent.com/NftClubCash/kensho-exclusive/main/png/",
//   discountExpTokensX1k: 1000,
//   expForLvl0: 1700,
// };

// const nftCollection = {
//   "0x7527D0c9E354C7221Fd45C14709fF1f9DF98CeBE": pixelguyTest,
//   "0x17B0dEEaF65797A67352e92F062E79636Ea68d42": shoraiTest,
//   "0xa032a264eF482053B97F3493ff50ADF66e37B802": kenshoTest,
//   "0x95B403E77AEBFA978D6FaA9D59C009a09A130a62": kenshoXTest,
// };
// // SmartBch TESTNET END //

export default {
  components: { Popup, NftCardLeveling },

  data() {
    return {
      rpc: null,

      nftCA: 0,
      discountExpTokensX1k: 950,

      collection: "Kensho",
      filter: "all",
      sorting: "Power",

      maxLevel: 100,
      levelToUpgradeTo: [25, 50, 75, 102],
      expTokensIncPerLevel: [30, 114, 562, 2717],
      expFor1LvlUpgrdTo: [
        0, 100, 130, 160, 190, 220, 250, 280, 310, 340, 370, 400, 430, 460, 490,
        520, 550, 580, 610, 640, 670, 700, 730, 760, 790, 904, 1018, 1132, 1246,
        1360, 1474, 1588, 1702, 1816, 1930, 2044, 2158, 2272, 2386, 2500, 2614,
        2728, 2842, 2956, 3070, 3184, 3298, 3412, 3526, 3640, 4202, 4764, 5326,
        5888, 6450, 7012, 7574, 8136, 8698, 9260, 9822, 10384, 10946, 11508,
        12070, 12632, 13194, 13756, 14318, 14880, 15442, 16004, 16566, 17128,
        17690, 20407, 23124, 25841, 28558, 31275, 33992, 36709, 39426, 42143,
        44860, 47577, 50294, 53011, 55728, 58445, 61162, 63879, 66596, 69313,
        72030, 74747, 77464, 80181, 82898, 85615, 88332, 91049,
      ],

      contract: {},

      maxNftsInCollection: 12000,

      provider: null,
      signer: null,

      currColl: null,

      nft2LookupFrom: 1,
      nft2LookupTo: 1,

      nftInfo: {},
      filteredNfts: [],

      // sort options
      sortOptions: [
        "Power",
        "EXP needed to reach next level",
        "% in level",
        "Number ascending",
        "Number descending",
      ],
    };
  },

  methods: {
    showPopup(_title, _text, _timeOut) {
      this.$refs.popup.show(_title, _text, _timeOut);
    },

    correctNft2LookupFromTo: function () {
      const _maxNfts = 100;

      const _intFrom = parseInt(this.nft2LookupFrom);
      const _intTo = parseInt(this.nft2LookupTo);

      if (_intFrom !== this.nft2LookupFrom) {
        this.nft2LookupFrom = _intFrom;
      }

      if (_intTo !== this.nft2LookupTo) {
        this.nft2LookupTo = _intTo;
      }

      if (this.nft2LookupFrom < 1) {
        this.nft2LookupFrom = 1;
      } else if (this.nft2LookupFrom > this.maxNftsInCollection) {
        this.nft2LookupFrom = this.maxNftsInCollection;
      }

      if (this.nft2LookupTo < this.nft2LookupFrom) {
        this.nft2LookupTo = this.nft2LookupFrom;
      } else if (this.nft2LookupTo > this.nft2LookupFrom + _maxNfts) {
        this.nft2LookupTo = this.nft2LookupFrom + _maxNfts;
        this.showPopup("Max. " + _maxNfts + " at once", "", 3000);
      }
    },

    getLevel: function (_nftPower) {
      let levelMinPower;
      let _level = 0;
      do {
        ++_level;

        levelMinPower = this.getMinPower(_level);
      } while (levelMinPower <= _nftPower);
      return _level - 1;
    },

    addNfts: async function (_tokenIdStart, _tokenIdEnd) {
      let that = this;

      if (this.signer === null) {
        this.showPopup("Please connect ", "your wallet first", 0);
        return;
      }

      if (_tokenIdEnd === _tokenIdStart) {
        this.showPopup(`Adding NFT ${_tokenIdStart}`, "", 3000);
      } else {
        this.showPopup(
          `Adding NFTs ${_tokenIdStart} to ${_tokenIdEnd}`,
          "",
          3000
        );
      }

      // shift window of NFT numbers for next addition
      that.nft2LookupFrom = _tokenIdEnd + 1;
      that.nft2LookupTo = _tokenIdEnd + 1 + (_tokenIdEnd - _tokenIdStart);

      let _tokenIds = [];
      for (let _tokenId = _tokenIdStart; _tokenId <= _tokenIdEnd; ++_tokenId) {
        if (that.filteredNfts.includes(_tokenId)) {
          console.log(_tokenId, "is already in filteredNfts");
          continue;
        }
        _tokenIds.push(_tokenId);
      }

      const _leveler = this.contract["leveler"];
      const _powerArr = await _leveler["getPower(address,uint16[])"](
        that.nftCA,
        _tokenIds
      );

      let _sacrificePriceArr = await that.contract[
        "graveyard"
      ].getSacrificePriceBulk(that.nftCA, _tokenIds);

      for (let _idx in _tokenIds) {
        const _tokenId = _tokenIds[_idx];
        const _power = _powerArr[_idx];
        const _sacrificePrice = _sacrificePriceArr[_idx];

        const _level = that.getLevel(_power);

        const _minpwrLevel = that.getMinPower(_level);
        const _minpwrNextLevel = that.getMinPower(_level + 1);
        const _pctInLevelX100 = Math.min(
          10000,
          Math.round(
            (((_power - _minpwrLevel) / (_minpwrNextLevel - _minpwrLevel)) *
              10000) /
              0.7
          )
        );
        let _expTokensUntilNextLevel = 0;
        if (_level < that.maxLevel) {
          _expTokensUntilNextLevel = Math.max(
            1,
            Math.ceil(
              (((that.expFor1LvlUpgrdTo[_level + 1] *
                this.discountExpTokensX1k) /
                1000) *
                (_minpwrNextLevel -
                  (10 / 7) * _power +
                  (3 / 7) * _minpwrLevel)) /
                (_minpwrNextLevel - _minpwrLevel)
            )
          );
        }
        // console.log(i, tokenId, level, uiIdx, that.expFor1LvlUpgrdTo[_level+1], pctInLevelX100);
        that.nftInfo[_tokenId] = {
          power: _power,
          level: _level,
          sacrificePrice: _sacrificePrice,
          minpwr: _minpwrLevel,
          pctInLevelX100: _pctInLevelX100,
          expTokensUntilNextLevel: _expTokensUntilNextLevel,
          expTokensForUpgrade: _expTokensUntilNextLevel,
          nftPowerAfterUpgrade: _minpwrNextLevel,
          nftLevelAfterUpgrade: _level + 1,
          pctInLevelX100AfterUpgrade: 0,
          image: that.currColl.pictureLink + _tokenId + ".png",
        };
        that.filteredNfts.push(_tokenId);
      }

      console.log("sorting NFTs");
      that.sortNfts(that.sorting);
    },

    selectCollection: function (_param) {
      let that = this;

      if (_param !== that.collection) {
        that.filteredNfts = [];
        that.nftInfo = [];
      }
      that.collection = _param;

      switch (_param) {
        case "PixelGuyClub":
          that.nftCA = window.address["pgc"];
          that.maxNftsInCollection = 10000;
          break;
        case "Shorai":
          that.nftCA = window.address["shorai"];
          that.maxNftsInCollection = 10000;
          break;
        case "Kensho":
          that.nftCA = window.address["kensho"];
          that.maxNftsInCollection = 12000;
          break;
        case "Kensho Exclusive":
          that.nftCA = window.address["kenshoX"];
          that.maxNftsInCollection = 240;
          break;

        default:
          that.showPopup("Collection not implemented", "", 3000);
      }
      that.currColl = nftCollection[that.nftCA];
      that.discountExpTokensX1k = that.currColl.discountExpTokensX1k;
    },

    getPower: function (_tokenId) {
      return this.nftInfo[_tokenId].power;
    },

    getMinPower: function (level) {
      if (level > this.maxLevel + 1) {
        level = this.maxLevel + 1;
      }
      return this.currColl.minpower[level];
    },

    sortNfts: function () {
      let that = this;
      const _param = that.sorting;
      switch (_param) {
        case "Number ascending":
          that.filteredNfts.sort(function (a, b) {
            return a - b;
          });
          break;
        case "Number descending":
          that.filteredNfts.sort(function (a, b) {
            return b - a;
          });
          break;
        case "Power":
          that.filteredNfts.sort(function (a, b) {
            return (
              that.nftInfo[b].staked - that.nftInfo[a].staked ||
              that.nftInfo[b].power - that.nftInfo[a].power ||
              a - b
            );
          });
          break;
        case "EXP needed to reach next level":
          that.filteredNfts.sort(function (a, b) {
            return (
              that.nftInfo[b].staked - that.nftInfo[a].staked ||
              that.nftInfo[a].expTokensUntilNextLevel -
                that.nftInfo[b].expTokensUntilNextLevel ||
              a - b
            );
          });
          break;
        case "% in level":
          that.filteredNfts.sort(function (a, b) {
            return (
              that.nftInfo[b].staked - that.nftInfo[a].staked ||
              that.nftInfo[b].pctInLevelX100 - that.nftInfo[a].pctInLevelX100 ||
              a - b
            );
          });
          break;
        default:
          that.showPopup("Sorting not implemented", "", 3000);
      }
    },

    checkExpTokensForUpgrade: function (_tokenId) {
      let that = this;

      that.nftInfo[_tokenId].expTokensForUpgrade = parseInt(
        that.nftInfo[_tokenId].expTokensForUpgrade
      );

      that.calculatePowerIncreaseSolidityV2(
        that.nftInfo[_tokenId].expTokensForUpgrade,
        _tokenId
      );
      if (that.nftInfo[_tokenId].nftLevelAfterUpgrade >= that.maxLevel) {
        that.getExpForFullLvlUpgrade(_tokenId);
        return;
      }
    },

    calculatePowerIncreaseSolidityV2: function (_expTokens, _tokenId) {
      let startLvl = this.nftInfo[_tokenId].level;
      let startPwrX10k = this.getPowerVue(_tokenId);

      let result2 = this.upgrade1NftGasSavings(
        _expTokens,
        _tokenId,
        startPwrX10k,
        startLvl
      );

      // update nft info
      this.nftInfo[_tokenId].nftPowerAfterUpgrade = result2.newPwrX10k;
      this.nftInfo[_tokenId].nftLevelAfterUpgrade = result2.newLvl;
      this.nftInfo[_tokenId].expTokensForUpgrade = result2.expTokensConsumed;
      this.nftInfo[_tokenId].pctInLevelX100AfterUpgrade = this.getPctX100InLvl(
        result2.newPwrX10k,
        result2.newLvl
      );
    },

    getPowerVue: function (_tokenId) {
      return this.nftInfo[_tokenId].power;
    },

    getPctX100InLvl: function (_pwrX10k, _level) {
      let _minpwrLevel = this.getMinPower(_level);
      let _minpwrNextLevel = this.getMinPower(_level + 1);
      return Math.min(
        10000,
        Math.round(
          (((_pwrX10k - _minpwrLevel) / (_minpwrNextLevel - _minpwrLevel)) *
            10000) /
            0.7
        )
      );
    },

    upgrade1NftGasSavings: function (
      _expTokens,
      _tokenId,
      startPwrX10k,
      startLvl
    ) {
      // this is the version that was developed for Dogechain
      const _verbose = false;
      if (_verbose) {
        console.log("Rewritten levelling contract START");
        console.log("_expTokens", _expTokens);
        console.log("startPwrX10k", startPwrX10k);
        console.log("startLvl", startLvl);
      }

      let that = this;
      let expTokensAtStart = _expTokens;

      if (startLvl >= that.maxLevel) {
        if (_verbose) {
          console.log("Start level >= max level");
          console.log("upgrade not possible");
        }
        // return instead of require for execution in loop
        // (upgrading 1 NFT for multiple levels beyond higher upgrading costs)

        let expTokensConsumed = 0;
        let newPwrX10k = startPwrX10k;
        let newLvl = startLvl;
        return { expTokensConsumed, newPwrX10k, newLvl };
      }

      // upgrade is possible

      let newLvl = startLvl;

      let _minPwrStartLvlX10k = that.getMinPower(startLvl);
      let _startPwrX10k = startPwrX10k;

      let exp4NextLvlUpgrd = Math.floor(
        (that.expFor1LvlUpgrdTo[newLvl + 1] * that.discountExpTokensX1k) / 1000
      );
      if (_verbose) {
        console.log("exp4NextLvlUpgrd", exp4NextLvlUpgrd);
      }

      // convert power within level to extra EXP tokens
      _expTokens += Math.floor(
        ((_startPwrX10k - _minPwrStartLvlX10k) * 100 * exp4NextLvlUpgrd) /
          ((that.getMinPower(startLvl + 1) - _minPwrStartLvlX10k) * 70)
      );
      if (_verbose) {
        console.log("_expTokens", _expTokens);
        console.log("_startPwrX10k", _startPwrX10k);
        console.log("_minPwrStartLvlX10k", _minPwrStartLvlX10k);
        console.log(
          "that.getMinPower(startLvl + 1)",
          that.getMinPower(startLvl + 1)
        );
      }

      ////////////////////////////
      // full level upgrades... //
      ////////////////////////////
      let uiIdx = 0;
      // BEGIN function getUpgradeInfoIndex(uint8 _level) public view returns (uint8)
      const lastIdx = that.levelToUpgradeTo.length - 1;
      if (newLvl >= that.levelToUpgradeTo[lastIdx]) {
        uiIdx = lastIdx;
      }

      while (newLvl >= that.levelToUpgradeTo[uiIdx]) {
        uiIdx++;
      }
      // END function getUpgradeInfoIndex(uint8 _level) public view returns (uint8)

      let levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
      let expTokensIncPerLevel = Math.floor(
        (that.expTokensIncPerLevel[uiIdx] * that.discountExpTokensX1k) / 1000
      );
      if (_verbose) {
        console.log("expTokensIncPerLevel", expTokensIncPerLevel);
      }

      while (_expTokens >= exp4NextLvlUpgrd && newLvl < that.maxLevel) {
        ++newLvl;
        _expTokens -= exp4NextLvlUpgrd;

        // NFT is in level newLvl
        if (newLvl >= levelWithHigherCost - 1) {
          // upgrades are getting more expensive -- update variables
          ++uiIdx;

          levelWithHigherCost = that.levelToUpgradeTo[uiIdx];
          expTokensIncPerLevel = Math.floor(
            (that.expTokensIncPerLevel[uiIdx] * that.discountExpTokensX1k) /
              1000
          );
          if (_verbose) {
            console.log(
              "NFT in level",
              newLvl,
              "expTokensIncPerLevel",
              expTokensIncPerLevel
            );
          }
        }

        // cost for next upgrade
        exp4NextLvlUpgrd += expTokensIncPerLevel;
        if (_verbose) {
          console.log(
            "Full level upgraded: NFT is in level",
            newLvl,
            "Next full level upgrade costs",
            exp4NextLvlUpgrd
          );
        }
      }

      // now _expTokens are not enough for next full level upgrade
      // => check if we can continue with partial level upgrades

      if (_verbose) {
        console.log(
          "After full level upgrades: NFT in level",
          newLvl,
          " -- Upgrade to next level costs:",
          exp4NextLvlUpgrd
        );
      }

      ///////////////////////////////
      // partial level upgrades... //
      ///////////////////////////////
      ///////////////////
      // Readable code (may not be up to date) //
      ///////////////////
      // let _minPwrAfterFullLvlUpgrdX10k = that.getMinPower(newLvl);

      // let newPwrX10k = _minPwrAfterFullLvlUpgrdX10k; // final result if partial upgrade is not possible
      // if (_expTokens > 0 && newLvl < that.maxLevel) {
      //   let _minPwrAfterFullLvlUpgrdPlus1X10k = that.getMinPower(newLvl + 1);

      //   let _ptlUpgrd = Math.floor(
      //     (_expTokens *
      //       (_minPwrAfterFullLvlUpgrdPlus1X10k - _minPwrAfterFullLvlUpgrdX10k) *
      //       70) /
      //       (100 * exp4NextLvlUpgrd)
      //   ); // linear approximation

      //   newPwrX10k += _ptlUpgrd;
      // }

      ///////////////////
      // Optimised code //
      ///////////////////
      let _pwrAfterUpgrade = that.getMinPower(newLvl); // final result if partial upgrade is not possible
      if (_expTokens > 0 && newLvl < that.maxLevel) {
        _pwrAfterUpgrade += Math.floor(
          (_expTokens *
            70 *
            (that.getMinPower(newLvl + 1) - _pwrAfterUpgrade)) /
            (100 * exp4NextLvlUpgrd)
        ); // linear approximation
      }

      _expTokens = 0; // otherwise the consumed EXP tokens are not correct -- only needed for website, not for contract

      if (_verbose) {
        console.log(
          "after full + partial level upgrades: level:",
          newLvl,
          "power:",
          _pwrAfterUpgrade,
          "exp4NextLvlUpgrd:",
          exp4NextLvlUpgrd,
          "minpower(newLvl), (newLvl+1):",
          that.getMinPower(newLvl),
          that.getMinPower(newLvl + 1)
        );
      }

      let expTokensConsumed = expTokensAtStart - _expTokens;

      return { expTokensConsumed, newPwrX10k: _pwrAfterUpgrade, newLvl };
    },

    getExpForFullLvlUpgrade: function (_tokenId) {
      const _verbose = false;

      const that = this;

      // make nftLevelAfterUpgrade integer
      that.nftInfo[_tokenId].nftLevelAfterUpgrade = parseInt(
        that.nftInfo[_tokenId].nftLevelAfterUpgrade
      );

      that.nftInfo[_tokenId].nftLevelAfterUpgrade =
        that.set_A2Mod_between_Min_and_Max(
          that.nftInfo[_tokenId].nftLevelAfterUpgrade,
          that.nftInfo[_tokenId].level,
          that.maxLevel
        );

      // handle the case in which nftLevelAfterUpgrade is <= level
      if (
        that.nftInfo[_tokenId].nftLevelAfterUpgrade <=
        that.nftInfo[_tokenId].level
      ) {
        that.nftInfo[_tokenId].expTokensForUpgrade = 1;
        that.calculatePowerIncreaseSolidityV2(
          that.nftInfo[_tokenId].expTokensForUpgrade,
          _tokenId
        );
        return;
      }

      // nftLevelAfterUpgrade > level
      let _expTokens = that.nftInfo[_tokenId].expTokensUntilNextLevel;
      for (
        let _level = that.nftInfo[_tokenId].level + 1;
        _level < that.nftInfo[_tokenId].nftLevelAfterUpgrade;
        ++_level
      ) {
        if (_verbose) {
          console.log("upgrade from level " + _level + " to " + _level + 1);
        }

        _expTokens += Math.floor(
          (that.expFor1LvlUpgrdTo[_level + 1] * that.discountExpTokensX1k) /
            1000
        ); // cost to upgrade from level to level +1
      }

      let _res1 = this.upgrade1NftGasSavings(
        _expTokens,
        _tokenId,
        that.nftInfo[_tokenId].power,
        that.nftInfo[_tokenId].level
      );

      let testlevel = _res1.newLvl;
      // let testPctX100 = this.getPctX100InLvl(
      //   result.newPwrX10k,
      //   result.newLvl
      // );
      let _exp4NextLvlUpgrd = Math.floor(
        (that.expFor1LvlUpgrdTo[testlevel + 1] * that.discountExpTokensX1k) /
          1000
      );
      let _expTokensInFinalLvl = Math.round(
        ((_res1.newPwrX10k - that.getMinPower(testlevel)) *
          100 *
          _exp4NextLvlUpgrd) /
          ((that.getMinPower(testlevel + 1) - that.getMinPower(testlevel)) * 70)
      ); // note: round gives correct results, floor does not in rare cases

      if (_expTokensInFinalLvl !== 0) {
        _expTokens -= _expTokensInFinalLvl;

        let keepGoing = true;
        do {
          console.log("checking level");
          let _res2 = this.upgrade1NftGasSavings(
            _expTokens,
            _tokenId,
            that.nftInfo[_tokenId].power,
            that.nftInfo[_tokenId].level
          );
          let _tstlvl = _res2.newLvl;
          // let testPctX100 = this.getPctX100InLvl(_res2.newPwrX10k, _res2.newLvl);

          if (_tstlvl < that.nftInfo[_tokenId].nftLevelAfterUpgrade) {
            console.log("testlevel is too low: ", _expTokens);
            _expTokens += 1;
          } else {
            keepGoing = false;
          }
        } while (keepGoing);
      }

      that.nftInfo[_tokenId].expTokensForUpgrade = Math.max(1, _expTokens);

      that.calculatePowerIncreaseSolidityV2(
        that.nftInfo[_tokenId].expTokensForUpgrade,
        _tokenId
      );
    },

    set_A2Mod_between_Min_and_Max: function (A2Mod, _Min, _Max) {
      if (A2Mod < _Min) {
        return _Min;
      } else if (A2Mod > _Max) {
        return _Max;
      } else {
        return A2Mod;
      }
    },
  },

  mounted: function () {
    this.rpc = window.ethereum;

    this.provider = new ethers.providers.Web3Provider(this.rpc, "any");

    this.provider
      .send("eth_requestAccounts", [])
      .then(() => {
        this.signer = this.provider.getSigner();

        this.contract["leveler"] = new ethers.Contract(
          window.address["leveler"],
          NftLeveler.abi,
          this.provider.getSigner()
        );

        this.contract["graveyard"] = new ethers.Contract(
          window.address["graveyard"],
          Graveyard.abi,
          this.provider.getSigner()
        );
      })
      .then(() => {
        this.selectCollection("Kensho");
      });
  },
};
</script>


<style>
button {
  height: 60px;
}
input {
  height: 40px;
  background: transparent;
  color: white;
  border-color: #0075ff;
  border-radius: 10px;
}
label {
  height: 20px;
}
/* td,
th,
label,
span,
h1,
h2,
h3,
h4,
h5,
h6,
b {
  color: #0075ff;
} */
</style>